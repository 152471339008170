import Form from "react-bootstrap/Form";
import {useContext} from "react";
import AuthContext from "../../../context/AuthProvider";
import log from "../../../Logger";
import {Accordion, Container} from "react-bootstrap";


const DiagSummaryWrite = () => {
    const { setTask, task } = useContext(AuthContext);

    const handleMessageChange = event => {
        setTask({...task, diagSummary: event.target.value})
    };

    const handleFocusIn = event => {
        log('DiagSummaryWrite: (enter to input field)');
    }

    const handleFocusOut = event => {
        log('DiagSummaryWrite: (' + event.target.value  + ' )');
    }
    return (
        <>
            <Container>
                    <h2>Диагноз</h2>
            </Container>
            
            {task.hyposCheck &&
                    <Accordion className={'mt-2'}>
                        <Accordion.Item className={'acc-highlighted'} eventKey={'1'}>
                            <Accordion.Header onClick={(event) => log('DiagHypos (' + event.target.ariaExpanded + ',"Гипотезы")' )}>{'Гипотезы'}</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    <ul className={'background-grey py-2'}>
                                        {task.hyposCheck.split('\n').map(item => <li className={'text-start'}>{item}</li>)}
                                    </ul>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }

            <h3 className={'mt-3'}>Выводы по работе с устройством</h3>
            <Form.Control as="textarea" rows={3} disabled={true} value={task.mainDiagnos}/>

            <h3 className={'mt-3'}>Постройте диагностическое заключение письменно в краткой форме записи</h3>
            <p>Например: С по 1,2,3 <a href="">Смотреть инструкцию записи диагноза</a></p>
            <Form.Control as="textarea" rows={3} onChange={handleMessageChange} value={task.diagSummary} onFocus={handleFocusIn} onBlur={handleFocusOut}/>
        </>
    );
}

export default DiagSummaryWrite;
